export const TENANT = process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : (window.location.pathname.split('/')[1] !== "app" ? window.location.pathname.split('/')[1] : "") 
export const API_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 
`http://127.0.0.1:8000/${TENANT}/api` : 
(process.env.REACT_APP_API_URL ? 
  `${process.env.REACT_APP_API_URL}/${TENANT}/api` : 
  `${window.location.origin}/${TENANT}/api`);
export const WS_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 
`http://127.0.0.1:8001/${TENANT}/websocket` : 
(process.env.REACT_APP_API_URL ? 
  `${process.env.REACT_APP_API_URL}/${TENANT}/websocket` : 
  `${window.location.origin}/${TENANT}/websocket`);
export const BASE_URL = `${window.location.origin}/${TENANT}/`
export const HTTP_TIMEOUT = 10000;
export const DEFAULT_FIELDS = ['firstname', 'lastname', 'nickname']
export const DEVICE = {
  mobile: `(min-width: 320px)`,
  tablet: `(min-width: 768px)`,
  laptop: `(min-width: 1024px)`,
  laptopL: `(min-width: 1440px)`,
  desktop: `(min-width: 2560px)`,
};
export const OFA_TENANT = ['ofa-sapienza']
export const IN_APP_TENANT = ['logica-scuole', 'orientamento-iulm', 'orientamento-constructor']